import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetAcknowledgedEmployees } from "../api";
import { Employee, EmptyEmployee } from "../types";
import EmployeeDialog from "./EmployeeDialog";

export default function EmployeesPage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [employees, setEmployees] = useState<Employee[]>([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<Employee>(EmptyEmployee);

  useEffect(() => {
    setIsLoading(true);
    apiGetAcknowledgedEmployees()
      .then((data) => {
        setIsLoading(false);
        setEmployees(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, []);

  return (
    <Container maxWidth="lg">
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <EmployeeDialog
        open={isDialogOpen}
        data={selectedEmployee}
        onDismiss={() => setIsDialogOpen(false)}
      />
      <TableContainer component={Paper}>
        <Table size="small" padding="normal">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Acknowledged Date</TableCell>
              <TableCell>User Comments</TableCell>
              <TableCell>Manager Comments</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((row) => (
              <TableRow key={row.code}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.division}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.comments}</TableCell>
                <TableCell>{row.managerComments}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setSelectedEmployee(row);
                      setIsDialogOpen(true);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
