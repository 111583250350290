import { getAccessToken } from "./auth";
import { Employee, NotificationInfo } from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

type Params = Record<string, string>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    url.searchParams.append(name, params[name]);
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetActiveEmployee(): Promise<Employee> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/get",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Employee = await response.json();
  return data;
}

export async function apiGetAcknowledgedEmployees(): Promise<Employee[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/listacknowledged",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Employee[] = await response.json();
  return data;
}

export async function apiSubmitAcknowledgement(
  comments: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "acknowledgement",
    body: { comments },
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiGetNotificationInfos(): Promise<NotificationInfo[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "notificationinfo/list",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: NotificationInfo[] = await response.json();
  return data;
}

export async function apiSaveNotificationInfo(
  divisionCode: string,
  sendEmailsFrom: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "notificationinfo/save",
    body: { divisionCode, sendEmailsFrom },
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiDeleteNotificationInfo(
  divisionCode: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "DELETE",
    path: "notificationinfo/delete",
    body: { divisionCode },
  });
  if (!response.ok) throw Error(await response.text());
}

export async function apiSendRemindersForDivision(
  divisionCode: string,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "sendmail/remindersfordivision",
    params: { divisionCode },
  });
  if (!response.ok) throw Error(response.statusText);
}
